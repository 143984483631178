import corporatesIcon from './assets/corporatesIcon.svg';
import investorsIcon from './assets/investors.svg';

export const featuresTitle = 'Your investor’s time is valuable';

export const featuresDescription = (
  'Traditional trustee portals are black boxes that can take days or weeks to process access requests. ' +
  'InvestorSet gives investors the access they need, when they need it, so they can trade faster.'
);

export const featuresCollection = [
  {
    icon: corporatesIcon,
    key: 'corporates',
    title: 'Corporates',
    collection: [
      'Bulk message current and former holders',
      'Dedicated email uploading to any folder',
      'File & folder drag and drop uploading',
      'File & folder level permissioning',
      'Usage analytics',
      'Watermarking',
      '24x7 U.S. based phone & email support',
    ],
  },
  {
    icon: investorsIcon,
    key: 'investors',
    title: 'Investors',
    collection: [
      'Customizable file upload notifications by email',
      'Same-day team-member provisioning',
      'Manage all repositories in one place',
      'Mobile friendly',
      'One-click access requests',
      'One-click bulk downloading',
      'Search by file, folder and date uploaded',
      'Keyword search within documents, across file types',
    ],
  },
];
